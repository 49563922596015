@import "https://cdn.jsdelivr.net/npm/reset-css@5.0.2/reset.min.css";
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #0fff50;
}

:root {
  --color-neon-green: #0fff50;
  --tablet-screen-width: 768px;
}

*, :after, :before {
  box-sizing: border-box;
}

::selection {
  color: #000;
  background-color: var(--color-neon-green);
}

html {
  color: var(--color-neon-green);
  background-color: #000;
  font-family: Monaco, monospace;
  font-size: 16px;
}

code {
  line-height: initial;
}

li:not(:last-child) {
  margin-bottom: 5px;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

a[target="_blank"] {
  cursor: alias;
}

a:focus, a:hover, a:active {
  color: #000;
  background-color: var(--color-neon-green);
  outline: 0;
  text-decoration: none !important;
}

.terminal, .cowsay {
  max-width: var(--tablet-screen-width);
  margin: 1em;
}

.section > * {
  margin-bottom: 1em;
}

.command {
  margin-left: 5px;
  margin-right: 5px;
}

.blinker {
  opacity: 1;
  border-left: 7px solid #0fff50;
  height: 15px;
  margin-bottom: -2px;
  margin-left: -5px;
}

.blinker.blinking {
  animation: .9s steps(2, start) infinite blinking;
}

@keyframes blinking {
  to {
    visibility: hidden;
  }
}

.about {
  flex-direction: row;
  display: flex;
}

.avatar {
  width: 100px;
  height: 100px;
}

@media (width <= 250px) {
  ul.about-item {
    width: 100%;
  }
}

@media (250px < width <= 325px) {
  ul.about-item {
    width: 80%;
  }
}

@media (325px < width <= 465px) {
  ul.about-item {
    width: 60%;
  }
}

@media (465px < width <= 540px) {
  ul.about-item {
    width: 40%;
  }
}

@media (width <= 425px) {
  .ref {
    display: none;
  }
}

@media (width <= 500px) {
  .sha {
    display: none;
  }
}

@media (width <= 540px) {
  .about {
    flex-direction: column;
  }

  img.about-item {
    align-self: center;
    margin-bottom: 10px;
  }

  .avatar {
    width: 128px;
    height: 128px;
  }

  ul.about-item {
    align-self: center;
  }

  ul.about-item:not(:last-child) {
    margin-bottom: 5px;
  }
}
/*# sourceMappingURL=index.5ed1ea08.css.map */
